






















































































import { Component, Vue, Prop } from "vue-property-decorator";
import FscMultiselect from "@/components/FscMultiselect.vue";
import Datepicker from "@/components/Datepicker.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import { namespace } from "vuex-class";
import cloneDeep from "lodash/cloneDeep";

const PaymentDocumentLocalFilterModule = namespace("payment-document/localFilter");

@Component({
  components: {
    FscMultiselect,
    Datepicker,
    AbortButton,
  },
})
export default class AutomaticPaymentsDocumentsFilter extends Vue {
  public name = "AutomaticPaymentsDocumentsFilter";

  @Prop()
  public allLicenseClasses!: any;

  @Prop()
  public allPaymentWorkflows!: any;

  @PaymentDocumentLocalFilterModule.State("filter")
  public paymentDocumentsLocalFilter: any;

  public invoiceTypeOptions = [
    { id: 601, name: "ZB" },
    { id: 602, name: "AZR" },
    { id: 603, name: "EAR" },
    { id: 604, name: "Stornorechnung" },
  ];

  public selectedLicenseClasses: any = [];
  public selectedInvoiceTypes: any = [];
  public selectedPaymentType: any = null;
  public selectedDateFrom: any = null;
  public selectedDateTo: any = null;

  public mounted(): void {
    if (this.paymentDocumentsLocalFilter instanceof Object) {
      this.initAutomaticPaymentsFilter();
    }
  }

  public onAbortFilter(): void {
    this.selectedLicenseClasses = [];
    this.selectedInvoiceTypes = [];
    this.selectedPaymentType = null;
    this.selectedDateFrom = null;
    this.selectedDateTo = null;
    const filter = {
      dateFrom: null,
      dateTo: null,
      licenseClasses: null,
      documentTypeIds: null,
      paymentWorkflowId: null,
      offset: 0,
    };
    this.$emit("on-abort-filter", {
      filter: filter,
    });
  }

  public onFilter(): void {
    const filter = {
      dateFrom: this.selectedDateFrom,
      dateTo: this.selectedDateTo,
      licenseClasses: this.licenseClasses,
      documentTypeIds: this.selectedInvoiceTypes?.length > 0 ? this.selectedInvoiceTypes : null,
      paymentWorkflowId: this.selectedPaymentType ? this.selectedPaymentType.id : null,
      offset: 0,
    };

    this.$emit("on-filter", {
      filter: filter,
    });
  }

  public get licenseClasses(): any {
    if (this.selectedLicenseClasses?.length === 0) {
      return null;
    } else {
      return this.selectedLicenseClasses;
    }
  }

  public get paymentWorkflowOptions(): Array<any> {
    return this.allPaymentWorkflows;
  }

  public initAutomaticPaymentsFilter(): void {
    if (this.paymentDocumentsLocalFilter instanceof Object) {
      const savedAutomaticPaymentsFilter = cloneDeep(this.paymentDocumentsLocalFilter);
      this.selectedDateTo = savedAutomaticPaymentsFilter.dateTo;
      this.selectedDateFrom = savedAutomaticPaymentsFilter.dateFrom;
      this.selectedLicenseClasses = savedAutomaticPaymentsFilter.licenseClasses;
      this.selectedInvoiceTypes = savedAutomaticPaymentsFilter.documentTypeIds;
      this.selectedPaymentType = savedAutomaticPaymentsFilter.paymentWorkflowId
        ? this.paymentWorkflowOptions.find((pw: any) => (pw.id = savedAutomaticPaymentsFilter.paymentWorkflowId))
        : null;
    }
  }
}
